import { apiPost, apiGet } from '../utils/http';
import { func } from 'prop-types';

// 订单页获取当前订单的授权单
export async function getOrderTicket(data = {}) {
    const api = 'book/zhima/orderTicket';
    return await apiPost(api, data);
}

// checkout 下单页获取当前用户可用预授权单
export async function getFreeTicket(data = {}) {
    const api = 'book/zhima/freeTicket';
    return await apiPost(api, data);
}

// 获取orderStr
export async function getOrderStr(data = {}) {
    const api = 'book/zhima/orderStr';
    return await apiPost(api, data);
}

// 获取签约
export async function getAuthContract(data = {}) {
    const api = 'book/zhima/contract';
    return await apiPost(api, data);
}

// 支付分轮询
export async function getUserAuthTicketInfo(data = {}) {
    const api = 'book/zhima/getUserAuthTicketInfo';
    return apiPost(api, data);
  }

// 上报授权结果
export async function zhimaAuthReport(data = {}) {
    const api = 'book/zhima/authReport';
    return await apiPost(api, data);
}

// 解冻
export async function zhimaUnfreeze(data = {}) {
    const api = 'book/zhima/unfreeze';
    return await apiPost(api, data);
}

// 绑定订单与芝麻授权码
export async function rebindOrderZhima(data = {}) {
    const api = 'book/zhima/rebindOrderZhima';
    return await apiPost(api, data);
}

// 中转页获取二维码信息
export async function getQrCodeInfo(params = {}) {
    const api = 'book/zhima/aliQrcode';
    return await apiGet(api, params);
}

export async function checkAuthStatus(data = {}) {
    const api = 'book/zhima/checkAuthStatus';
    return await apiPost(api, data);
}

export async function reAuthContract(data = {}) {
    const api = 'book/zhima/reAuthContract';
    return await apiPost(api, data);
}
